<template>
  <main class="row container_order">
    <div class="col-sm-12 p-0 page_title">
      <h1 class="o_ttl">{{ $t('ORDER_HISTORY.order') }}</h1>
      <p class="o_account">
        <span>
          <span>My account</span>
        </span>
        <span class="pl-1">
          <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
          <span>My Orders</span>
        </span>
        <span class="pl-1">
          <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
          <span>Order</span>
        </span>
      </p>
    </div>
    <div class="col-sm-12 order_info pb-6">
      <div class="row order_info--header mb-7">
        <div class="col-sm-6 o_details">{{
          $t('PRODUCT_DESCRIPTION.order_details')
        }}</div>
      </div>
      <div v-if="isStatusCanceled()">
        <p>{{ currentStatus?.text }}</p>
      </div>
      <div v-else class="row order_range d-block">
        <div class="col-sm-12 range_block pa-0 px-3">
          <ul class="range_ul_li">
            <li
              v-for="(status, index) in statuses"
              :key="index"
              :class="getStatusLineClass(index)"
            ></li>
          </ul>
          <ul class="range_bottom">
            <li
              class="range_item"
              v-for="(status, index) in user?.role === 'retail_client'
                ? retailStatuses
                : statuses"
              :key="index"
            >
              <span class="range_c1">{{ index + 1 }}</span>
              <p class="range_doc">{{ status.text }}</p>
              <span class="range_date">{{
                order?.created_at.split(' ')[0]
              }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row order_poduct_i">
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.order_number') }}</p>
          <span class="info_text">{{ order && order.id }}</span>
        </div>
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.date') }}:</p>
          <span class="info_text">{{
            order && order.created_at.replace(/ /g, '/')
          }}</span>
        </div>
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">E-mail:</p>
          <span class="info_text grey--text">{{
            order && order?.user?.email
          }}</span>
        </div>
        <div class="col-sm-3">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.total') }}:</p>
          <span class="info_text">
            ${{
              order &&
                order.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }}</span
          >
        </div>
      </div>

      <div class="row info_about_o mb-6">
        <h2 class="ma-0 text-uppercase">{{
          $t('ORDER_HISTORY.order_info')
        }}</h2>
        <div class="col-sm-12 pa-0 px-lg-12 px-sm-6">
          <v-expansion-panels flat class="product mb-1">
            <v-expansion-panel style="border-radius: 0">
              <v-expansion-panel-header class="pa-0">
                <span class="about_product">{{
                  $t('REGISTRATION.product')
                }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <main
                  class="container_order container pa-0"
                  style="width: unset !important"
                >
                  <OrderTableInfo
                    :order="order"
                    :activeLang="activeLang"
                    :openCustomizationDialog="openCustomizationDialog"
                  />
                </main>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-dialog v-model="castomizationDialog" :max-width="700">
              <v-card>
                <SelectedBrands
                  :logo="editing_item && editing_item.logo"
                  :brands="my_brands"
                  :selected_brands="selected_brands"
                  @onPositionChange.stop="onPositionChange"
                  @onLogoChange="editing_item.logo.logo = $event"
                />
              </v-card>
            </v-dialog>
          </v-expansion-panels>
          <div class="about_order">
            <div class="col-sm-12 px-2 order">
              <ul class="total_order pa-0 d-flex justify-space-between">
                <li class="sum_count">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4"
                      >{{
                        $t('PRODUCT_DESCRIPTION.second_table.totalCount')
                      }}: </b
                    ><span class="btn_product_sum">
                      {{ order && order.count }}
                    </span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.sum') }}:
                    </b>
                    <span class="btn_product_sum">
                      ${{
                        order &&
                          order.sum
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }}
                    </span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.discount') }}:
                    </b>
                    <span class="btn_product_sum">-0%</span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.saving') }}:
                    </b>
                    <span class="btn_product_sum"> ${{ 0 }} </span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="t_order_total btn_total">
                    <b class="btn_product_text mr-4"
                      >{{ $t('PRODUCT_DESCRIPTION.second_table.total') }}:
                    </b>
                    <span class="btn_product_sum">
                      ${{
                        order &&
                          order.sum
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                      }}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="about_total">
            <span class="order_total">{{
              $t('PRODUCT_DESCRIPTION.second_table.total')
            }}</span>
            <span class="order_sum order_sum--total"
              >${{
                order &&
                  order.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }}</span
            >
          </div>
          <div class="order__address">
            <div class=""> </div>
            <div class=""> </div>
          </div>
        </div>

        <div class="col-sm-12 pa-0 px-lg-8 px-sm-10">
          <div class="row order__address ma-0">
            <div class="col-sm-3 payment__terminal">
              <h3>{{ $t('ORDER_HISTORY.payment_terminal') }}</h3>
              <p>
                Покупатель:
                {{ order && order.user.first_name }}
                {{ order && order.user.last_name }}
              </p>
              <p>
                Email:
                {{ order && order.user.email }}
              </p>
            </div>
            <div class="col-sm-3 delivery__address">
              <h3>{{ $t('ORDER_HISTORY.delivery_address') }}:</h3>
              <p>
                Адрес:
                {{ JSON.parse(order?.delivery).address }}
              </p>
              <p>
                Страна:
                {{ JSON.parse(order?.delivery).country }}
              </p>
              <p>
                Почтовый индекс:
                {{ JSON.parse(order?.delivery).mailIndex }}
              </p>
            </div>
            <div class="col-sm-2 pa-0"></div>
            <div class="col-12 col-sm-4">
              <BaseButton
                :title="$t('ORDER_HISTORY.invoice')"
                disabled
                @click="redirectToSpecificRoute"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters justify-end repeat">
        <div class="col-12 col-sm-4">
          <BaseButton
            :title="$t('ORDER_HISTORY.retry')"
            @click="
              $router.push({ name: 'product description', query: { order } })
            "
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: Actions_orderHistory } = createNamespacedHelpers(
  'orderHistory'
)
const { mapState: State_auth } = createNamespacedHelpers('auth')
import SelectedBrands from '../content/SelectedBrands.vue'
import OrderTableInfo from './order/OrderTableInfo.vue'

import BaseButton from './../layout/BaseButton.vue'
const { mapState } = createNamespacedHelpers('cart')
export default {
  name: 'OrderAccount',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    SelectedBrands,
    OrderTableInfo,
  },
  filters: {
    floor(val) {
      return val && Math.floor(val)
    },
  },
  data() {
    return {
      savingPercentage: 5,
      order: null,
      shippingPrice: 20,
      baseUrl: '',
      activeLang: '',
      castomizationDialog: false,
      my_brands: [],
      selected_brands: [],
      editing_item: {},
      editing_sole: {},
      // statuses: [
      //   {
      //     value: 'new',
      //     isActive: false,
      //   },
      //   {
      //     value: 'pending',
      //     isActive: false,
      //   },
      //   {
      //     value: 'paid',
      //     isActive: false,
      //   },
      //   {
      //     value: 'canceled',
      //     isActive: false,
      //   },
      // ],
      statuses: [
        { value: 'Processing', text: 'Processing' },
        { value: 'payment_waiting', text: 'Awaiting Payment' },
        { value: 'in_production', text: 'In Production' },
        { value: 'on_hold', text: 'On Hold' },
        { value: 'completed', text: 'Completed' },
        { value: 'packaging', text: 'Packaging' },
        { value: 'shipping', text: 'Shipped' },
        { value: 'on_way', text: 'In Transit' },
      ],
      retailStatuses: [
        { text: 'В обработке' },
        { text: 'В ожидании оплаты' },
        { text: 'Упаковка товара' },
        { text: 'Отправка товара' },
        { text: 'В пути' },
        { text: 'Выполнен' },
      ],
      considerStatuses: [
        { value: 'canceled', text: 'Cancelled' },
        { value: 'returned', text: 'Returned' },
        { value: 'failed', text: 'Failed' },
      ],
      currentStatus: 'Выполнен',
    }
  },

  computed: {
    ...mapState(['cartItems']),
    ...State_auth(['user']),
    currentIndex() {
      if (this.user?.role === 'retail_client') {
        return this.retailStatuses.findIndex(
          (status) => status.value === this.currentStatus
        )
      } else {
        return this.statuses.findIndex(
          (status) => status.value === this.currentStatus
        )
      }
    },
    totalPriceWithSaving() {
      return this.order && this.order?.sum - this.saving
    },
    saving() {
      return (this.order && this.order?.sum * this.savingPercentage) / 100
    },
    subTotal() {
      let sum = this.order && this.order?.sum
      sum = sum - (sum * 5) / 100
      return Math.floor(sum)
    },
    total() {
      const shipping = 20
      const total = this.subTotal + shipping
      return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
  methods: {
    ...Actions_orderHistory(['fetchOrderById']),

    redirectToSpecificRoute() {
      const wholesale = true
      let routeName = ''
      if (wholesale) {
        routeName = 'invoice'
      } else {
        routeName = 'invoice form'
      }
      this.$router.push({ name: routeName })
    },
    isStatusCanceled() {
      const existingItem = this.considerStatuses?.find(
        (item) => item.value === this.order?.status
      )
      this.currentStatus = existingItem?.status?.text
      if (existingItem) {
        return true
      }
    },
    openCustomizationDialog(getSelectedBrands, sole_id) {
      this.castomizationDialog = true
      if (this.order.order_items[0].sole?.id && sole_id) {
        this.my_brands = getSelectedBrands.selected_brands
        this.selected_brands = getSelectedBrands.selected_brands.filter(
          (s) => s.sole_id === sole_id
        )
      } else {
        this.my_brands = getSelectedBrands.selected_brands
        this.selected_brands = getSelectedBrands.selected_brands
      }
    },
    getStatusLineClass(index) {
      // Функция, которая возвращает класс в зависимости от индекса статуса
      if (index === this.getStatusIndex(this.order?.status)) {
        return 'range_line' // Закрашиваем линию зеленым только для текущего статуса
      } else if (index < this.getStatusIndex(this.order?.status)) {
        return 'range_line' // Закрашиваем линию зеленым для статусов до текущего статуса
      } else {
        return 'range_line1' // Оставляем остальные линии красными
      }
    },
    getStatusIndex(status) {
      // Функция, которая возвращает индекс статуса в массиве statuses
      return this.statuses.findIndex((s) => s.value === status)
    },
  },
  async mounted() {
    const data = await this.fetchOrderById(this.$route.params.id)
    this.order = data
    this.baseUrl = this.$store.state.auth.API_URL
    this.activeLang = this.$store.state.language
  },
}
</script>

<style lang="scss" scoped>
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

%d_flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.product {
  .v-expansion-panel {
    button {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
}

.about_total {
  margin-top: 10px;
  background: white;
  padding: 8px 20px;
  @include ftz(1rem, 500, #666666);
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 15px !important;
  @media (max-width: 481px) {
    margin: 0;
  }
  .order_sum {
    font-family: 'Roboto Condensed';
    @include ftz(30px, 400, #666666);
    &--total {
      font-weight: 700;
    }
  }
  .order_price {
    @include ftz(0.875rem, 700, #666666);

    .order_sum {
      @include ftz(1.125rem, 400, #666666);
    }
  }

  .order_total {
    @include ftz(1.5rem, 700, #31343c);
    padding: 8px 10px;

    .order_sum {
      @include ftz(1.5rem, 700, #31343c);
    }
  }
}

.page_title {
  margin-left: 0;
  @media (max-width: 575px) {
    margin-left: 16px;
  }
}

.container_order {
  margin: 0;
  padding: 20px;
  background: #f9f9f9;
  @media (max-width: 575px) {
    padding: 0px;
  }
}
.o_ttl {
  letter-spacing: 1px;
  @include ftz(1.5rem, 600, $black);
}

.o_account {
  @include ftz(12px, 400, $gr_col);
  position: relative;
  letter-spacing: 0.2px;
  padding-bottom: 12px;
}

.order_info {
  height: 100hv;
  background: $white;
  &--header {
    width: 96%;
    margin: 0 auto;
  }
  .o_details {
    @include ftz(1.125rem, 500, $bl);
    padding-left: 0;
    @media (max-width: 481px) {
      @include ftz(0.75rem, 500, $bl);
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  .view_details {
    cursor: pointer;
    @include ftz(18px, 500, $bl_c);
    padding-right: 0;
    @media (max-width: 481px) {
      @include ftz(0.75rem, 500, $bl_c);
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-right: 12px;
      // margin-left: 2rem;
    }
  }

  .order_range {
    border: 1px solid $gr;
    width: 96%;
    height: 7rem;
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: 20px;
    @extend %d_flex;
    margin: 0 auto;

    @media (max-width: 481px) {
      width: 100%;
    }

    .range_block {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      position: relative;
      width: 1100px;
      height: 100px;
      padding-top: 20px !important;
      overflow-x: auto;
      margin: auto;

      .range_ul_li {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        width: 1100px;
        margin-bottom: 1rem;
        padding: 0;
        @media (max-width: 481px) {
          width: 979px;
        }

        .range_line {
          width: 30rem;
          height: 5px;
          background: $green_c;
          // margin-top: -32px;
          position: relative;
        }

        .range_line1 {
          width: 32rem;
          height: 5px;
          background: #e4e4e4;
          position: relative;
        }
      }

      .range_bottom {
        display: flex;
        flex-direction: row;
        gap: 68px;
        width: 1100px;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 2rem;
        padding: 0;

        @media (max-width: 481px) {
          margin: 0;
          width: 980px;
        }

        .range_item {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;

          .range_c1 {
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background: $green_c;
            border: 1px solid;
            color: #ffffff;
            display: flex;
            justify-content: space-around;
            margin-bottom: 1rem;
            position: absolute;
            bottom: 30px;
            &--disabled {
              background-color: #e4e4e4;
              color: #707070;
              border-color: #fff;
            }
          }

          .range_doc {
            margin-top: 1rem;
            @include ftz(0.875rem, 500, #31343c);

            @media (max-width: 481px) {
              margin-top: 1.2rem;
              @include ftz(0.65rem, 500, #31343c);
            }
          }
          .range_date {
            @include ftz(0.875rem, 500, #a4a4a4);
          }
        }
      }
    }
  }

  .order_poduct_i {
    margin: 2rem 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5px;
    @media (max-width: 481px) {
      margin: 2rem 0rem;
      padding: 0;
    }

    .col-sm-3 {
      @media (min-width: 374px) {
        flex: 0 0 auto;
        width: 25%;
        padding: 0;
      }
    }

    .o_line {
      border-right: 1px solid #f3f1f1;
    }

    .poduct_i_text {
      @include ftz(1rem, 400, #31343c);
      text-transform: capitalize;

      @media (max-width: 481px) {
        @include ftz(0.7rem, 400, #31343c);
      }
    }

    .info_text {
      @include ftz(1.125rem, 700, #31343c);

      @media (max-width: 897px) {
        @include ftz(1rem, 700, #31343c);

        @media (max-width: 770px) {
          @include ftz(0.655rem, 700, #31343c);

          @media (max-width: 481px) {
            @include ftz(0.5rem, 700, #31343c);
          }
        }
      }
    }
  }
  .info_about_o,
  .repeat {
    width: 96%;
    margin: 0 auto;
    @media (max-width: 481px) {
      width: 100%;
      margin: auto;
    }
  }

  .info_about_o {
    background: #fbfbfb;

    h2 {
      @include ftz(1rem, 500, #666666);
      text-align: center;
      padding: 15px;
    }

    .about_order {
      background: white;
      padding: 8px 20px;
      @include ftz(1rem, 500, #666666);
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 15px !important;
      margin-top: 10px;
      @media (max-width: 481px) {
        margin: 0;
      }
      .order {
        // max-width: 960px;
        overflow-x: auto;
      }
      .subtotal {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }

      .order_sum {
        font-family: 'Roboto Condensed';
        @include ftz(1.125rem, 400, #666666);
        &--total {
          font-weight: 700;
        }
      }

      .order_price {
        @include ftz(0.875rem, 700, #666666);

        .order_sum {
          @include ftz(1.125rem, 400, #666666);
        }
      }

      .order_total {
        @include ftz(1.5rem, 700, #31343c);
        padding: 8px 10px;

        .order_sum {
          @include ftz(1.5rem, 700, #31343c);
        }
      }
    }

    .info_payment {
      margin-left: 3rem;
      padding-top: 10px;
      margin-right: 2rem;
      overflow: hidden;
      margin-bottom: 0.5rem;

      .payment_b {
        .name_payment {
          @include ftz(1rem, 500, #666666);
        }
        .text_payment {
          @include ftz(0.75rem, 400, #666666);
        }
      }
    }
  }
}
.container_order {
  background-color: #fff;
}
.cart__item {
  display: grid;
}
.cart__item-container {
  display: grid;
  border: 1px solid $gr;
  &--top {
    grid-template-columns: 1fr 1fr 4fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &--bottom {
    grid-template-columns: 2fr 4fr;
    border-top: none;
  }
}
.item-container {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &__title {
    border-bottom: 1px solid $gr;
    background-color: $gr;
    text-transform: uppercase;
    @include ftz(16px, 400, $bl);
    text-align: center;
  }
  &__content {
    width: 100%;
    padding-top: 100%;
    & > div {
      position: absolute;
      inset: 0;
    }
    &-color {
      width: 50%;
      height: 50%;
    }
  }
  &__castomization-btn {
    height: 100%;
    border-right: 1px solid $gr;
  }
  &__castomization-logo {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}
.content {
  &__button {
    color: $grey;
    flex-grow: 1;
    cursor: pointer;
    &--increase {
      background-color: $green;
    }
    &--decrease {
      background-color: $gr;
    }
  }
}
.btn_item {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: 700 !important;
    width: 130px;
    padding: 5px 20px;
  }
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
  .btn_product_text {
    font-weight: bold !important;
  }
}
.sum_count {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: bold !important;
    width: 130px;
    padding: 5px 20px;
  }
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
  .btn_product_text {
    font-weight: bold !important;
  }
}
.btn_total {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px;
  width: 132px;
}
</style>
